import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="client-balance-chart"
export default class extends Controller {
  static targets = [ "chart" ];
  static values = { 
    clientId: String
  }

  connect() {
    var options = {
      chart: {
        width: 380,
        type: 'pie',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    };

    fetch(`/clients/${this.clientIdValue}/balance_chart.json`).then(response => {
      response.json().then(data => {
        options.series = data.series;
        options.labels = data.labels;
        
        this.chart = new ApexCharts(this.chartTarget, options);

        this.chart.render();
        this.rendered = true;
      })
    })

    // Set timeout to properly get the parent elements width
    //setTimeout(function() {
      
    //}, 200);  
  }

  disconnect() {
    if (this.rendered) {
      this.chart.destroy();
    }
  }
}
